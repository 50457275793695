import 'core-js/stable'
import 'regenerator-runtime/runtime'

import * as ActionCable from 'actioncable'

App = {}
App.cable = ActionCable.createConsumer()
if gon.store_name_arr.length > 0
  gon.store_name_arr.forEach (store_name, i) ->
    App.driver = App.cable.subscriptions.create {channel: 'DriverLogChannel', store_name: store_name}
else
App.driver = App.cable.subscriptions.create { channel: 'DriverLogChannel', store_name: gon.store_name },
  connected: ( e ) ->
    # Called when the subscription is ready for use on the server

  disconnected: ->
    # Called when the subscription has been terminated by the server

  received: (data) ->
    driver_log = data
    #$('#mapElNewId').attr('driver', Json.parse(data))
    moveDriverOnMap(data)

    new_order: ->
        @perform 'new_driver_log'
